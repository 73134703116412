<template>
	<Transition
		@before-enter="beforeEnter"
		@enter="enter"
		@before-leave="beforeLeave"
		@leave="leave"
	>
		<div
			v-if="showIf"
			class="content"
			ref="content"
		>
			<slot></slot>
		</div>
	</Transition>
</template>

<script>
import { defineComponent } from "vue";

/**
 * Usage: Use instead of vue transition element to expand element to height auto.
 * Pass prop :show-if to TransitionAutoHeight instead of v-if on child element.
 * The v-if is set directly on the TransitionAutoHeight component.
 * This ensures that padding and margins are expanded correctly.
 */
export default defineComponent({
	name: "TnTransitionAutoHeight",

	props: {
		/**
		 * Show/hide content and trigger auto height and fade animation
		 */
		showIf: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			observer: null,
		};
	},

	mounted() {
		if (this.$refs.content) {
			this.expandAndShowElement(this.$refs.content);
		}
	},

	updated() {
		if (this.$refs.content) {
			this.expandAndShowElement(this.$refs.content);
		}
	},

	beforeUnmount() {
		if (this.observer) this.observer.disconnect();
	},

	methods: {
		expandAndShowElement(el) {
			el.style.height = el.scrollHeight + "px";
			el.style.opacity = "1";

			if (!this.observer) {
				this.$nextTick(() => {
					this.observer = new MutationObserver(() => {
						this.expandAndShowElement(this.$refs.content);
					});

					this.observer.observe(this.$refs.content, { childList: true, subtree: true });
				});
			}
		},
		collapseAndFadeElement(el) {
			el.style.height = "0";
			el.style.opacity = "0";
		},
		beforeEnter(el) {
			this.collapseAndFadeElement(el);
		},
		enter(el) {
			this.expandAndShowElement(el);
		},
		beforeLeave(el) {
			this.expandAndShowElement(el);
		},
		leave(el) {
			this.collapseAndFadeElement(el);
		},
	},
});
</script>

<style lang="scss" scoped>
.content {
	transition:
		height 300ms cubic-bezier(0.8, 0, 0.2, 1),
		opacity 300ms cubic-bezier(0.8, 0, 0.2, 1);
}
</style>
